var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from 'react';
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { styleUnder, styleOver } from 'src/common/styleUtil';
;
function randId() {
    return Math.floor(Math.random() * 1500) + 1;
}
function seqRandArray(size) {
    var res = Array.from(Array(size).keys());
    res.sort(function () { return Math.random() - 0.5; });
    return res;
}
function imgUrl(tokenId) {
    return "https://public.youilman.com/sprite/".concat(tokenId, ".png");
}
var HomePreview = function (props) {
    var _a = useState([]), cardList = _a[0], setCardList = _a[1];
    var _b = useState([]), candidateList = _b[0], setCandidateList = _b[1];
    useEffect(function () {
        var _candidateList = [];
        var rndArr = seqRandArray(31);
        for (var i = 0; i < 31; i++) {
            var rnd = rndArr[i] + 1;
            _candidateList.push(rnd);
            new Image().src = imgUrl(rnd);
        }
        setCandidateList(_candidateList);
        var counter = 0;
        var interval = setInterval(function () {
            setCardList([
                _candidateList[(counter * 4) % 31],
                _candidateList[(counter * 4 + 1) % 31],
                _candidateList[(counter * 4 + 2) % 31],
                _candidateList[(counter * 4 + 3) % 31],
            ]);
            counter = counter + 1;
        }, 500);
        return function () { return clearInterval(interval); };
    }, []);
    return (React.createElement(PreviewContent, null,
        // 이미지를 사이즈 0으로 마운트시키기
        candidateList.map(function (card) { return React.createElement("img", { key: card, src: imgUrl(card), width: 0, style: { display: 'none' } }); }),
        cardList[0] && React.createElement(Sprite, { style: { left: 0, top: 0 }, src: imgUrl(cardList[0]) }),
        cardList[1] && React.createElement(Sprite, { style: { right: 0, top: 0 }, src: imgUrl(cardList[1]) }),
        cardList[2] && React.createElement(Sprite, { style: { left: 0, bottom: 0 }, src: imgUrl(cardList[2]) }),
        cardList[3] && React.createElement(Sprite, { style: { right: 0, bottom: 0 }, src: imgUrl(cardList[3]) })));
};
var PreviewContent = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  margin: auto;\n  ", "\n  ", "\n"], ["\n  position: relative;\n  margin: auto;\n  ", "\n  ", "\n"])), styleOver(500, "\n    width: 500px;\n    height: 500px;\n  "), styleUnder(500, "\n    width: 100vw;\n    height: 100vw;\n  "));
var Sprite = styled.img(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: absolute;\n  border-radius: 15px;\n  width: 46%;\n  margin: 2%;\n  height: auto;\n  border: 1px solid #ddd;\n"], ["\n  position: absolute;\n  border-radius: 15px;\n  width: 46%;\n  margin: 2%;\n  height: auto;\n  border: 1px solid #ddd;\n"])));
export default HomePreview;
var templateObject_1, templateObject_2;
