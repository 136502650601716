import { CONTENT_WIDTH } from 'src/common/const';
export function styleNarrow(styleStr) {
    var res = "@media screen and (max-width: ".concat(CONTENT_WIDTH, "px) {\n    ").concat(styleStr, "\n  }");
    return res;
}
export function styleWide(styleStr) {
    return "@media screen and (min-width: ".concat(CONTENT_WIDTH, "px) {\n    ").concat(styleStr, "\n  }");
}
export function styleUnder(width, styleStr) {
    var res = "@media screen and (max-width: ".concat(width, "px) {\n    ").concat(styleStr, "\n  }");
    return res;
}
export function styleOver(width, styleStr) {
    return "@media screen and (min-width: ".concat(width, "px) {\n    ").concat(styleStr, "\n  }");
}
