var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import ContractABI from 'res/Youilman.json';
import ContractOldABI from 'res/YouilmanOld.json';
var klaytn = window.klaytn;
var caver = window.caver;
var _a = process.env, YOUILMAN_CONTRACT_ADDRESS_BAOBAB = _a.YOUILMAN_CONTRACT_ADDRESS_BAOBAB, YOUILMAN_CONTRACT_ADDRESS_MAINNET = _a.YOUILMAN_CONTRACT_ADDRESS_MAINNET;
var contract = null;
if (window.caver) {
    contract = new window.caver.klay.Contract(ContractABI.abi, getContractAddress());
    window.contract = contract;
    klaytn.on('networkChanged', function () {
        contract = new window.caver.klay.Contract(ContractABI.abi, getContractAddress());
        console.log("changed ".concat(getKlaytnNetwork(), " : ").concat(getContractAddress()));
    });
}
export function getKlaytnNetwork() {
    if ((klaytn === null || klaytn === void 0 ? void 0 : klaytn.networkVersion) === 1001)
        return 'baobab';
    if ((klaytn === null || klaytn === void 0 ? void 0 : klaytn.networkVersion) === 8217)
        return 'mainnet';
    return 'nokaikas';
}
export function getContractAddress() {
    if (getKlaytnNetwork() === 'mainnet') {
        return YOUILMAN_CONTRACT_ADDRESS_MAINNET;
    }
    if (getKlaytnNetwork() === 'baobab') {
        return YOUILMAN_CONTRACT_ADDRESS_BAOBAB;
    }
    return '';
}
export function getOpenseaAddress() {
    if (getKlaytnNetwork() === 'baobab') {
        return "https://testnets.opensea.io/collection/you1man-official";
    }
    return "https://opensea.io/collection/you1man-official";
}
export function getAddress() {
    return __awaiter(this, void 0, void 0, function () {
        var accounts, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, klaytn.enable()];
                case 1:
                    accounts = _a.sent();
                    return [2 /*return*/, accounts[0]];
                case 2:
                    error_1 = _a.sent();
                    return [2 /*return*/, null];
                case 3: return [2 /*return*/];
            }
        });
    });
}
export function mintNft(address) {
    return __awaiter(this, void 0, void 0, function () {
        var params, estimatedGas, result, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    params = {
                        from: address,
                        to: getContractAddress(),
                        value: caver.utils.toPeb('9', 'KLAY'),
                        gas: 200000
                    };
                    return [4 /*yield*/, contract.methods.claim().estimateGas(params)];
                case 1:
                    estimatedGas = _a.sent();
                    params['gas'] = estimatedGas;
                    console.log(params);
                    return [4 /*yield*/, contract.methods.claim().send(params)];
                case 2:
                    result = _a.sent();
                    return [2 /*return*/, 'succeed'];
                case 3:
                    err_1 = _a.sent();
                    if (err_1.message.indexOf('User denied transaction') > -1) {
                        return [2 /*return*/, 'user_cancel'];
                    }
                    return [2 /*return*/, 'unknown'];
                case 4: return [2 /*return*/];
            }
        });
    });
}
window.withdrawOld = function (klayAmount) {
    return __awaiter(this, void 0, void 0, function () {
        var contractAddressOld, contractOld, params, estimatedGas;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    contractAddressOld = '0x8d4f3eb5742e04a0f26445d5c861aa2c0a5a3a6e';
                    contractOld = new window.caver.klay.Contract(ContractOldABI.abi, contractAddressOld);
                    _a = {};
                    return [4 /*yield*/, getAddress()];
                case 1:
                    params = (_a.from = _b.sent(),
                        _a.to = contractAddressOld,
                        _a.gas = 200000,
                        _a);
                    return [4 /*yield*/, contractOld.methods.withdraw(caver.utils.toPeb(klayAmount, 'KLAY')).estimateGas(params)];
                case 2:
                    estimatedGas = _b.sent();
                    params['gas'] = estimatedGas;
                    console.log(params);
                    return [4 /*yield*/, contractOld.methods.withdraw(caver.utils.toPeb(klayAmount, 'KLAY')).send(params)];
                case 3: return [2 /*return*/, _b.sent()];
            }
        });
    });
};
window.withdraw = function (klayAmount) {
    return __awaiter(this, void 0, void 0, function () {
        var params, estimatedGas;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _a = {};
                    return [4 /*yield*/, getAddress()];
                case 1:
                    params = (_a.from = _b.sent(),
                        _a.to = getContractAddress(),
                        _a.gas = 200000,
                        _a);
                    return [4 /*yield*/, contract.methods.withdraw(caver.utils.toPeb(klayAmount, 'KLAY')).estimateGas(params)];
                case 2:
                    estimatedGas = _b.sent();
                    params['gas'] = estimatedGas;
                    console.log(params);
                    return [4 /*yield*/, contract.methods.withdraw(caver.utils.toPeb(klayAmount, 'KLAY')).send(params)];
                case 3: return [2 /*return*/, _b.sent()];
            }
        });
    });
};
window.ownerClaim = function (tokenId) {
    return __awaiter(this, void 0, void 0, function () {
        var params, estimatedGas;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _a = {};
                    return [4 /*yield*/, getAddress()];
                case 1:
                    params = (_a.from = _b.sent(),
                        _a.to = getContractAddress(),
                        _a.gas = 200000,
                        _a);
                    console.log(params);
                    return [4 /*yield*/, contract.methods.ownerClaim(tokenId).estimateGas(params)];
                case 2:
                    estimatedGas = _b.sent();
                    params['gas'] = estimatedGas;
                    console.log(params);
                    return [4 /*yield*/, contract.methods.ownerClaim(tokenId).send(params)];
                case 3: return [2 /*return*/, _b.sent()];
            }
        });
    });
};
window.transferOwnership = function (addr) {
    return __awaiter(this, void 0, void 0, function () {
        var params, estimatedGas;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _a = {};
                    return [4 /*yield*/, getAddress()];
                case 1:
                    params = (_a.from = _b.sent(),
                        _a.to = getContractAddress(),
                        _a.gas = 200000,
                        _a);
                    console.log(params);
                    return [4 /*yield*/, contract.methods.transferOwnership(addr).estimateGas(params)];
                case 2:
                    estimatedGas = _b.sent();
                    params['gas'] = estimatedGas;
                    console.log(params);
                    return [4 /*yield*/, contract.methods.transferOwnership(addr).send(params)];
                case 3: return [2 /*return*/, _b.sent()];
            }
        });
    });
};
window.getHolders = function () {
    return __awaiter(this, void 0, void 0, function () {
        var result, amountMap, toBlock, bulk, st, ed, checksum, holder;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    result = '';
                    amountMap = {};
                    return [4 /*yield*/, caver.klay.getBlockNumber()];
                case 1:
                    toBlock = _a.sent();
                    bulk = 500000;
                    st = 84801389;
                    _a.label = 2;
                case 2:
                    if (!(st <= toBlock)) return [3 /*break*/, 5];
                    ed = Math.min(st + bulk - 1, toBlock);
                    console.log("checking block number ".concat(st, " ~ ").concat(ed));
                    return [4 /*yield*/, contract.getPastEvents('Transfer', {
                            fromBlock: st,
                            toBlock: ed
                        }).then(function (events) {
                            for (var e in events) {
                                var _a = events[e].returnValues, from = _a.from, to = _a.to, tokenId = _a.tokenId;
                                amountMap[from] = amountMap[from] || 0;
                                amountMap[to] = amountMap[to] || 0;
                                amountMap[from] = amountMap[from] - 1;
                                amountMap[to] = amountMap[to] + 1;
                            }
                        })];
                case 3:
                    _a.sent();
                    _a.label = 4;
                case 4:
                    st += bulk;
                    return [3 /*break*/, 2];
                case 5:
                    checksum = 0;
                    for (holder in amountMap) {
                        result += "".concat(holder, " ").concat(amountMap[holder], "\n");
                        checksum += amountMap[holder];
                    }
                    console.log(result);
                    console.log('checksum :', checksum);
                    return [2 /*return*/];
            }
        });
    });
};
/*
window.setUp = async function(_tokenIdList: Array<number>, _addressList: Array<string>) {
  const params = {
    from: await getAddress(),
    to: getContractAddress(),
    gas: 500000000
  };
  const tokenIdList: any = [];
  const addressList: any = [];
  for(let i=500; i<_addressList.length; i++) {
    tokenIdList.push(_tokenIdList[i]);
    addressList.push(_addressList[i]);
  }
  console.log(tokenIdList, addressList);
  const tokenIdHexList = tokenIdList.map((tokenId:any) => {
    return '0x' + tokenId.toString(16);
  });
  console.log(tokenIdHexList);
  const estimatedGas = await contract.methods.setUp(tokenIdHexList, addressList).estimateGas(params);
  params['gas'] = estimatedGas;
  console.log(params);
  return await contract.methods.setUp(tokenIdHexList, addressList).send(params);
}
*/ 
