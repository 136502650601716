var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from 'react';
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import HomePreview from './HomePreview';
import HomeButtonContainer from './HomeButtonContainer';
import { styleNarrow, styleWide, styleUnder, styleOver } from 'src/common/styleUtil';
import BannerImg from 'res/banner.png';
import { getAddress, mintNft, getKlaytnNetwork } from 'src/web3/youilman';
var Home = function () {
    var _a = useState({
        message: '',
        messageType: 'warning',
    }), messageObj = _a[0], setMessageObj = _a[1];
    var _b = useState(''), address = _b[0], setAddress = _b[1];
    function fetchAddress() {
        return __awaiter(this, void 0, void 0, function () {
            var addr;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setMessageObj({ message: '카이카스 지갑에 로그인 해주세요.' });
                        return [4 /*yield*/, getAddress()];
                    case 1:
                        addr = _a.sent();
                        setMessageObj({ message: '' });
                        if (!addr) {
                            setMessageObj({ message: '카이카스 지갑 주소를 가져오지 못했습니다.', messageType: 'warning' });
                            return [2 /*return*/];
                        }
                        setMessageObj({ message: '지갑에 연결되었습니다.', messageType: 'success' });
                        setAddress(addr);
                        return [2 /*return*/];
                }
            });
        });
    }
    function requestMint(address) {
        return __awaiter(this, void 0, void 0, function () {
            var result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setMessageObj({ message: '카이카스 지갑에서 트랜젝션을 확인해주세요', messageType: 'success' });
                        return [4 /*yield*/, mintNft(address)];
                    case 1:
                        result = _a.sent();
                        if (result === 'user_cancel') {
                            setMessageObj({ message: '트랜젝션이 사용자에 의해 취소되었습니다.', messageType: 'warning' });
                            return [2 /*return*/];
                        }
                        if (result === 'succeed') {
                            setMessageObj({ message: '트랜젝션이 제출되었습니다.' });
                            return [2 /*return*/];
                        }
                        setMessageObj({ message: '트랜젝션에 문제가 발생했습니다.', messageType: 'warning' });
                        return [2 /*return*/];
                }
            });
        });
    }
    useEffect(function () {
        if (!window.klaytn) {
            setMessageObj({ message: '카이카스 지갑이 설치되지 않았습니다', messageType: 'warning', action: 'kaikas_install' });
            return;
        }
        fetchAddress();
    }, []);
    return (React.createElement("div", { className: "home" },
        React.createElement(BannerContainer, null,
            React.createElement("img", { src: BannerImg, alt: "\uC720\uBD80\uB0A8 \uC77C\uC5B5 \uB9CC\uB4E4\uAE30: \uC720\uC77C\uB9CC" })),
        React.createElement(HomeTitle, { className: "home__title" },
            getKlaytnNetwork() === 'baobab' && React.createElement(NetworkAlert, null, "[Baobab Testnet]"),
            getKlaytnNetwork() === 'unknown' && React.createElement(NetworkAlert, null, "[\uC9C0\uC6D0\uD558\uC9C0 \uC54A\uB294 \uB124\uD2B8\uC6CC\uD06C]"),
            React.createElement("div", null, "\uC720\uC77C\uB9CC \uCEE4\uBBA4\uB2C8\uD2F0"),
            " ",
            React.createElement("div", null, "NFT \uBBFC\uD305")),
        React.createElement(HomeDesc, null,
            React.createElement("div", null, "\uC720\uC77C\uB9CC\uC744 \uC0AC\uB791\uD574\uC8FC\uC154\uC11C \uAC10\uC0AC\uD569\uB2C8\uB2E4."),
            React.createElement("br", null),
            ' ',
            React.createElement("div", null, "No roadmap, No white paper,"),
            ' ',
            React.createElement("div", null, "Only for community")),
        React.createElement(ContentContainer, null,
            React.createElement(HomeContent, { className: "home__content" },
                React.createElement(SectionLeft, null,
                    React.createElement(HomePreview, null)),
                React.createElement(SectionRight, null,
                    React.createElement(HomeButtonContainer, { messageObj: messageObj, address: address, handleMintClick: address ? requestMint : fetchAddress })))),
        React.createElement(Footer, null,
            React.createElement("div", null, "Copyright \u24D2 Youilman channel & developer."),
            React.createElement("div", null, "ALL RIGHTS RESERVED 2022"))));
};
var NetworkAlert = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: red;\n  display: block !important;\n"], ["\n  color: red;\n  display: block !important;\n"])));
var BannerContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: 0;\n  img {\n    width: 100%;\n    min-height: 110px;\n    object-fit: cover;\n  }\n"], ["\n  font-size: 0;\n  img {\n    width: 100%;\n    min-height: 110px;\n    object-fit: cover;\n  }\n"])));
var ContentContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding: 40px 0;\n"], ["\n  padding: 40px 0;\n"])));
var HomeTitle = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  text-align: center;\n  padding: 60px 0 0px;\n  color: #102741;\n  font-size: 33px;\n  line-hieght: 1.2;\n  div {\n    display: inline-block;\n  }\n"], ["\n  text-align: center;\n  padding: 60px 0 0px;\n  color: #102741;\n  font-size: 33px;\n  line-hieght: 1.2;\n  div {\n    display: inline-block;\n  }\n"])));
var HomeDesc = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  text-align: center;\n  padding: 20px 0 0px;\n  color: #406771;\n  font-size: 23px;\n  line-height: 1.2;\n  div {\n    display: inline-block;    \n  }\n"], ["\n  text-align: center;\n  padding: 20px 0 0px;\n  color: #406771;\n  font-size: 23px;\n  line-height: 1.2;\n  div {\n    display: inline-block;    \n  }\n"])));
var HomeContent = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  margin: auto;\n  ", "\n  ", "\n"], ["\n  display: flex;\n  justify-content: center;\n  margin: auto;\n  ", "\n  ", "\n"])), styleWide(""), styleNarrow("\n    flex-direction: column;\n    align-items: center;\n  "));
var SectionLeft = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  position: relative;\n  ", "\n  ", "\n"], ["\n  position: relative;\n  ", "\n  ", "\n"])), styleOver(500, "\n    width: 500px;\n    height: 500px;\n  "), styleUnder(500, "\n    width: 100vw;\n    height: 100vw;\n  "));
var SectionRight = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  position: relative;\n  ", "\n  ", "\n"], ["\n  position: relative;\n  ", "\n  ", "\n"])), styleOver(500, "\n    width: 500px;\n    height: 500px;\n  "), styleUnder(500, "\n    width: 100%;\n    height: 100vw;\n  "));
var Footer = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-wrap: wrap;\n  gap: 2px;\n  min-height: 50px;\n  padding: 50px 0;\n  background: #0d2058;\n  color: #9097b0;\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-wrap: wrap;\n  gap: 2px;\n  min-height: 50px;\n  padding: 50px 0;\n  background: #0d2058;\n  color: #9097b0;\n"])));
export default Home;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
